import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import SectionWrapper, { ContentWrapper } from './disclaimer.style';

const Disclaimer = () => {
  const Data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/cryptoModern/illustration1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
        {/*<div className="image">
            <Fade up>
              <GatsbyImage
                src={
                  (Data.illustration !== null) | undefined
                    ? Data.illustration.childImageSharp.gatsbyImageData
                    : {}
                }
                alt="Privacy Illustration"
              />
            </Fade>
          </div>*/}
          <div className="content">
            <Heading content="Nadi is providing incorrect or misleading responses. What's going on?" />
            {/*<Text content="Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiu Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiu" />*/}
            <p class="small-font">
        In striving to serve as a valuable assistant, Nadi may at times provide information that isn't accurate or might be misleading. Despite these challenges, Nadi is constantly working to deliver the best possible answers based on your data.</p>
        <p class="small-font">
          This phenomenon, often referred to as "hallucination," is a consequence of some of the inherent challenges faced by advanced Generative AI models, including Nadi. For instance, Nadi may not always have access to the latest data in certain fields, leading to potential confusion when dealing with recent developments. Additionally, Nadi might present quotations that seem credible or compelling, yet lack factual basis. Essentially, Nadi is capable of producing responses that appear to be correct but are in fact erroneous.
        </p>
        <p class="small-font">
          We advise users not to rely solely on Nadi for critical information and to approach any advice given with caution, especially in matters of significant consequence.
        </p>
        <p class="small-font">
          Feedback is crucial for improvement. If you find any response less helpful, please use the thumbs down button to inform us, or share your feedback and suggestions at <a href="mailto:support@nadiai.ai">support@nadiai.ai</a>
      </p>
          </div>
          
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Disclaimer;
